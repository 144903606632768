<template>
    <div>
        <div class="page-title">实体报告订单</div>
        
        <a-tabs @change="statusChanged" v-model="status">
            <a-tab-pane tab="已采集" key="waite"></a-tab-pane>
            <a-tab-pane tab="已提交" key="submit"></a-tab-pane>
            <a-tab-pane tab="已完成" key="finished"></a-tab-pane>
        </a-tabs>
        
        <a-table :columns="table.columns"
                 :rowKey="record => record.id || record.key"
                 :data-source="table.data"
                 :pagination="table.pagination"
                 @change="tableChanged"
                 :locale="{emptyText:'暂无订单'}"
                 class="members-table"
                 :loading="table.loading"
        >
            <div slot="time" slot-scope="time">
                {{ formatTime(time) }}
            </div>
            
            <div slot-scope="name,order" slot="name">
                
                <div class="flex flex-center">
                    <template v-if="app.id !== order.app_id">
                        <div :title="`用户归属于:${order.ast_app.nickname}`" class="third-app-avatar">
                            <img :src="order.ast_app.avatar" alt="">
                        </div>
                    </template>
                    
                    <router-link class="member-name" :to="`/ast/members/${order.ast_member.id}`">
                        {{ order.ast_member.mark_name || order.ast_member.name }}
                    </router-link>
                </div>
            
            </div>
            
            
            <div class="avatar" slot="avatar" slot-scope="avatar,order">
                <template v-if="order.ast_member.avatar">
                    <img :src="order.ast_member.avatar" alt="">
                </template>
                <template v-else>
                    <img src="/assets/icons/dfava.png" alt="">
                </template>
            </div>
            
            <div slot="level_name" slot-scope="levelName,item">
                <template v-if="item.level_id">
                    <div class="level-card">
                        Lv.{{ item.level_id }}{{ levelName }}
                    </div>
                </template>
                <template v-else>游客</template>
            
            </div>
            
            <div slot="def" slot-scope="val">{{ val || ' - ' }}</div>
            
            <div slot="mp" slot-scope="val">{{ val }}%</div>
            <div slot="lp" slot-scope="val">{{ val }} / 9</div>
            
            <div slot="address" slot-scope="val,item">
                
                <div @click="activeAddressInfo(item)">
                    <template v-if="item.mail_type === 'company' ">
                        <a><small>工作室代收</small></a>
                    </template>
                    <template v-else-if="item.name">
                        <a>{{ item.mail_type ? '用户直收' : '查看' }}</a>
                    </template>
                    <template v-else>
                        <a><span class="text-minor">未填写</span></a>
                    </template>
                </div>
            
            </div>
            
            <div slot="op" slot-scope="val,item">
                
                <template v-if="item.customer_express_no ">
                    <div>
                        <button class="mail-btn btn mb-2">报告已发货</button>
                    </div>
                </template>
                <template v-else-if="item.print_paid_status && !item.shop_print_no ">
                    <div>
                        <button class="status-btn btn mb-2">排产中</button>
                    </div>
                </template>
                <template v-else-if="item.print_paid_status ">
                    <div>
                        <button class="status-btn btn mb-2">印刷中</button>
                    </div>
                </template>
                
                <template v-if="item.unlock_status === 0 ">
                    
                    <button class="btn mb-2" @click="unlock(item.id)">开启检测</button>
                
                </template>
                <template v-else-if="!item.print_paid_status">
                    
                    <button @click="gotoDetail(item)" class="btn mb-2">报告补充</button>
                    <br>
                    <button @click="activePrint(item)" class="btn mb-2">发起印刷</button>
                
                </template>
                <template v-else-if="item.mail_type">
                    <button @click="gotoDetail(item)" class="btn mb-2">查看报告</button>
                </template>
                
                <br>
                <div>
                    <button class="btn mr-2" @click="showReportQrcode(item)">报告</button>
                    <button class="btn " @click="showImageQrcode(item,'image')">档案</button>
                </div>
                
                <div v-if="!item.print_paid_status">
                    <button @click="deleteOrder(item)" class="btn btn-danger mt-2">删除订单</button>
                </div>
            
            </div>
            
            <div slot="paid_status" slot-scope="status">
                <template v-if="status === 1">在线支付</template>
                <template v-else-if="status === 2">兑换</template>
                <template v-else>未支付</template>
            </div>
        </a-table>
        
        <a-modal width="1000px" @ok="confirmEditAddress" v-model="showEditAddressModal" title="修改收货地址">
            
            <table class="at-table">
                <tr>
                    <td>
                        <a-input addonBefore="收件人" v-model="detail.name"></a-input>
                    </td>
                    <td colspan="2">
                        <a-input addonBefore="电话" v-model="detail.phone"></a-input>
                    </td>
                </tr>
                <tr>
                    <td>
                        <a-input addonBefore="省份" v-model="detail.province"></a-input>
                    </td>
                    <td>
                        <a-input addonBefore="城市" v-model="detail.city"></a-input>
                    </td>
                    <td>
                        <a-input addonBefore="地区" v-model="detail.area"></a-input>
                    </td>
                </tr>
                <tr>
                    <td colspan="3">
                        <a-input addonBefore="详细地址" v-model="detail.address"></a-input>
                    </td>
                </tr>
            </table>
        </a-modal>
        
        <a-modal v-model="showAddressInfo" :footer="null">
            <div slot="title">
                <span>用户快递信息</span>
                <small class="ml-4"><a @click="activeEditAddress">修改</a></small>
            </div>
            <table class="at-table full-with">
                <tr>
                    <th>收件人</th>
                    <td>{{ detail.name || '-' }}</td>
                    <th>电话</th>
                    <td>{{ detail.phone || '-' }}</td>
                </tr>
                <tr>
                    <th>地址</th>
                    <td colspan="2">
                        {{ detail.province }}
                        {{ detail.city }}
                        {{ detail.area }}
                        {{ detail.address }}
                    </td>
                </tr>
            </table>
            <div class="mt-4" v-if="detail.customer_express_no">
                <template v-if="!expressInfo">
                    <div slot="title">快递信息</div>
                    <span>暂无快递信息...  </span>
                </template>
                <template v-else>
                    
                    <div slot="title">
                        <span>【{{ expTitleInfo.company }}】{{ expTitleInfo.no }}</span>
                    </div>
                    
                    <a-timeline>
                        <template v-for="(st,sk) in expressInfo.data ">
                            
                            <a-timeline-item :key="sk">
                                <div><small>{{ st.time }}</small></div>
                                {{ st.context }}
                            </a-timeline-item>
                        </template>
                    </a-timeline>
                </template>
            </div>
        </a-modal>
        
        <a-modal title="发起印刷" v-model="showPrintInfo">
            
            <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
                <a-form-item label="纸张">
                    <div class="paper-item-list">
                        
                        <template v-for="(item,ik) in papers">
                            
                            <div class="paper-info" :key="ik" :class="{'active': paperId === item.id }"
                                 @click="selectPaper(item)">
                                <div><img :src="item.thumb" alt=""></div>
                                <div>{{ item.name }}</div>
                            </div>
                        
                        </template>
                    </div>
                </a-form-item>
                <a-form-item label="邮寄方式">
                    <a-radio-group v-model="mailType">
                        <a-radio value="customer">快递给用户</a-radio>
                        <a-radio value="company">快递给工作室</a-radio>
                    </a-radio-group>
                </a-form-item>
                <a-form-item label="支付方式">
                    <a-radio-group v-model="printPayType">
                        <a-radio value="pt">减扣印刷份数（{{ paperInfo.cost }}）</a-radio>
                        <a-radio value="online">在线支付({{ paperInfo.price }}元)</a-radio>
                    </a-radio-group>
                </a-form-item>
            </a-form>
            
            <div slot="footer" class="footer">
                <a-button @click="showPrintInfo = false ">取消</a-button>
                <a-button type="primary" @click="confirmPrint">支付</a-button>
            </div>
        
        </a-modal>
        
        <a-modal v-if="qrcode" v-model="showQrcodeModal" title="请微信扫码支付" :footer="null" :destroyOnClose="true"
                 :maskClosable="false">
            <div class="qrcode-container">
                <img :src="qrcode" alt="">
            </div>
        </a-modal>
        
        <a-modal width="320px" v-model="showMiniQrcodeModal" :title="qrcodeTitle" :footer="null">
            <div class="text-center mb-2">
                <img style="width: 180px" v-if="miniQrcodeUrl" :src="miniQrcodeUrl" alt="">
            </div>
            <div class="text-center">
                <small>{{ qrcodeTips }}</small>
            </div>
        </a-modal>
        
        <at-modal title="用户职业填写" v-model="showJobModal" @ok="syncImageInfo">
            <at-input v-model="imageInfo.jobs" class="full-with" placeholder="请输入职业" highlight unit="职业"/>
        </at-modal>
    
    </div>
</template>

<script>

import api from "../../../repo/api";
import moment from "moment/moment";
import axios from "axios";
import auth from "@/auth";
import AtInput from "@/components/AtInput.vue";
import AtModal from "@/components/AtModal.vue";

export default {
    components: {AtModal, AtInput},
    props: {
        app: Object
    },
    data() {
        return {
            showEditAddressModal: false,
            detailId: 0,
            showQrcodeModal: false,
            expressInfo: null,
            showExpressInfoModal: false,
            qrcodeTitle: '',
            qrcodeTips: '',
            expCompany: {},
            query: {
                status: 1
            },
            imageInfo: {},
            table: {
                columns: [
                    {
                        title: 'ID',
                        dataIndex: 'id',
                        width: '110px',
                    },
                    {
                        title: '时间',
                        dataIndex: 'paid_at',
                        scopedSlots: {customRender: 'time'},
                    },
                    {
                        title: '用户ID',
                        dataIndex: 'ast_member.member_id',
                    },
                    {
                        title: '支付类型',
                        dataIndex: 'paid_status',
                        scopedSlots: {customRender: 'paid_status'},
                    },
                    {
                        title: '头像',
                        dataIndex: 'avatar',
                        scopedSlots: {customRender: 'avatar'},
                    },
                    {
                        title: '昵称/备注名',
                        dataIndex: 'name',
                        scopedSlots: {customRender: 'name'},
                    },
                    {
                        title: '报告ID',
                        dataIndex: 'report_id',
                    },
                    {
                        title: '档案',
                        dataIndex: 'mp_text',
                        scopedSlots: {customRender: 'mp'},
                    },
                    {
                        title: '相片',
                        dataIndex: 'lp_text',
                        scopedSlots: {customRender: 'lp'},
                    },
                    {
                        title: '快递',
                        dataIndex: 'address',
                        scopedSlots: {customRender: 'address'},
                    },
                    {
                        title: '操作',
                        dataIndex: 'op',
                        scopedSlots: {customRender: 'op'},
                    }
                ],
                loading: true,
                pagination: {
                    defaultCurrent: 1,
                    current: 1,
                    defaultPageSize: 10,
                    total: 1,
                    pageSize: 15,
                    showTotal: (total, range) => {
                        return `当前${range[0]}-${range[1]}，共 ${total} 个会员`;
                    },
                },
                data: []
            },
            showAddressInfo: false,
            showPrintInfo: false,
            showJobModal: false,
            detail: {},
            mailType: 'customer',
            printPayType: "pt",
            printType: 'normal',
            papers: [],
            paperId: 0,
            paperInfo: {},
            qrcode: null,
            ft: null,
            orderNo: null,
            shopPrintNo: "",
            shopPrintCom: "",
            showMiniQrcodeModal: false,
            miniQrcodeUrl: "",
            status: "waite",
            expTitleInfo: {
                company: "",
                no: "",
            }
        }
    },
    mounted() {
        this.getFirstList()
        this.getExpressCom()
    },
    destroyed() {
        if (this.ft) clearTimeout(this.ft)
    },
    methods: {
        statusChanged(val) {
            console.log(val);
            this.status = val
            this.getFirstList()
        },
        getExpressCom() {
            api.get('/ast-detect/exp-companies', (res) => {
                
                if (res.code !== 0) return;
                
                this.expCompany = res.data
            });
        },
        activeEditAddress() {
            this.showAddressInfo = false
            this.showEditAddressModal = true
        },
        confirmEditAddress() {
            
            let params = {
                name: this.detail.name,
                phone: this.detail.phone,
                province: this.detail.province,
                city: this.detail.city,
                area: this.detail.area,
                address: this.detail.address,
                id: this.detail.id
            }
            
            this.$loading.show()
            
            api.post('/ast-detect/update-paper-address', params).then(res => {
                
                this.$message.data(res)
                this.$loading.hide()
                
                if (res.code !== 0) return;
                
                this.showEditAddressModal = false
                
                this.getList()
            })
        },
        showExpressInfo(item, type) {
            
            this.$loading.show()
            
            this.detail = JSON.parse(JSON.stringify(item))
            
            let cmp = type === 'company' ? item.company_express_com : item.customer_express_com;
            let no = type === 'company' ? item.company_express_no : item.customer_express_no;
            
            this.expTitleInfo.company = this.expCompany[cmp]
            this.expTitleInfo.no = no
            
            let params = {id: item.id, type: type};
            
            api.get('/ast-detect/express-info', params, (res) => {
                this.$loading.hide()
                
                if (res.code !== 0) return this.$message.data(res);
                
                
                this.expressInfo = res.data
                
                this.showExpressInfoModal = true
                
            });
        },
        syncImageInfo() {
            this.$loading.show()
            
            api.post('/ast-detect/sync-image', this.imageInfo, res => {
                
                this.$loading.hide()
                
                if (res.code !== 0) {
                    return this.$message.data(res)
                }
                
                this.showJobModal = false
                
                this.activePrint(this.detail)
                
            })
        },
        deleteOrder(item) {
            this.$confirm({
                title: "确定要删除这个订单吗？",
                content: "订单删除数据不可恢复",
                onOk: () => {
                    this.$loading.show()
                    
                    api.post('/ast-detect/delete-order', {id: item.id}, data => {
                        
                        this.$loading.hide()
                        this.$message.data(data)
                        
                        if (data.code === 0) {
                            this.getList()
                        }
                    })
                }
            })
        },
        activePrint(detail) {
            this.detail = detail
            
            this.$loading.show()
            
            api.post('/ast-detect/print-check', {id: detail.id}, res => {
                
                if (res.code !== 0) {
                    this.$loading.hide()
                    
                    if (res.code === 350) {
                        
                        this.imageInfo = {
                            jobs: '',
                            id: this.detail.id
                        }
                        
                        this.showJobModal = true
                        
                    }
                    
                    if (res.code === 388) {
                        
                        return this.$confirm({
                            title: res.msg,
                            cancelText: "取消",
                            okText: "去完善",
                            onOk: () => {
                                this.$router.push('/ast/paper/basic')
                            }
                        })
                        
                    }
                    
                    return this.$message.data(res)
                }
                
                
                api.get('/ast-detect/print-papers', (res) => {
                    
                    this.$loading.hide()
                    
                    if (res.code !== 0) return;
                    
                    this.showPrintInfo = true
                    
                    this.mailType = 'customer'
                    this.printPayType = 'online'
                    
                    this.papers = res.data
                    
                    if (!this.papers.length) return;
                    
                    this.paperId = this.papers[0].id
                    this.paperInfo = this.papers[0]
                })
                
            })
        },
        showReportQrcode(item) {
            if (!item.print_paid_status) {
                return this.$error({
                    title: "发起印刷后，才会生成本次电子报告（实体副本）",
                });
            }
            this.showImageQrcode(item, 'report')
        },
        showImageQrcode(item, type) {
            
            let url = axios.defaults.baseURL + '/collocation/com/mini-qrcode?path=';
            
            if (type === 'image') {
                this.qrcodeTitle = '协助填写修改用户形象档案'
                this.qrcodeTips = '微信扫一扫快速进入用户形象档案'
                url += "packagesB/userImageInfo/userImageInfo?am_id=" + item.am_id;
            } else if (type === 'report') {
                this.qrcodeTitle = '查看本次实体报告副本（在线报告）'
                this.qrcodeTips = '微信扫一扫快速查看用户检测报告'
                url += "packagesB/report/report?am_id=" + item.am_id + '&auth-code=' + item.report.auth_code;
            }
            
            
            url += '&token=' + auth.getToken()
            url += '&_mini_app_id=' + auth.appId()
            
            this.miniQrcodeUrl = encodeURI(url);
            
            this.showMiniQrcodeModal = true
        },
        gotoDetail(item) {
            this.$router.push(`/ast/paper/detail/${item.id}`)
        },
        activeAddressInfo(item) {
            this.detail = item
            this.showAddressInfo = true
            
            if (item.customer_express_no) {
                this.showExpressInfo(item, 'customer');
            }
        },
        formatTime(time) {
            return moment(time).format('yyyy-MM-DD HH:mm')
        },
        getFirstList() {
            this.query.page = 1
            this.getList()
        },
        selectPaper(item) {
            this.paperInfo = item
            this.paperId = item.id
        },
        confirmPrint() {
            let data = {
                id: this.detail.id,
                mail_type: this.mailType,
                print_pay_type: this.printPayType,
                print_paper_id: this.paperInfo.id
            }
            
            this.$loading.show()
            
            api.post('/ast-detect/print', data, (res) => {
                
                this.$loading.hide()
                
                this.$message.data(res)
                
                if (res.code !== 0) return;
                
                if (res.data.paid) {
                    
                    this.showPrintInfo = false
                    
                    this.getList()
                }
                
                if (res.data['data_url']) {
                    this.qrcode = res.data['data_url']
                    
                    this.orderNo = res.data['no'];
                    
                    this.showPrintInfo = false
                    
                    this.showQrcodeModal = true
                    
                    this.fetPayStatus()
                }
                
            })
            
        },
        fetPayStatus() {
            api.get('/ast-detect/pay-status', {
                no: this.orderNo,
            }, (res) => {
                
                if (res.data.status === true) {
                    
                    this.showQrcodeModal = false
                    
                    this.$message.success('支付成功！');
                    
                    return this.getList();
                }
                
                this.ft = setTimeout(() => {
                    this.fetPayStatus()
                }, 2000)
            })
        },
        getList() {
            
            this.table.loading = true
            this.query.status = this.status
            
            api.get('/ast-detect/orders', this.query, (data) => {
                this.table.data = data.data.data
                this.table.pagination.current = data.data.current_page
                this.table.pagination.total = data.data.total
                this.table.pagination.pageSize = data.data.per_page
                
                this.table.loading = false
                
            })
        },
        unlock(id) {
            this.$confirm({
                title: '开启AI检测分析后，将扣除主理人账号1点（次）数，确定扣除开启吗？',
                onOk: () => {
                    
                    this.$loading.show()
                    
                    api.post('/ast-detect/unlock', {id}, (res) => {
                        
                        this.$loading.hide()
                        
                        this.$message.data(res)
                        
                        if (res.code === 0) this.getList()
                        
                    })
                    
                }
            })
        },
        tableChanged(pagination) {
            this.query.page = pagination.current
            this.getList()
        }
    }
}
</script>
<style lang="less" scoped>
.avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
    
    img {
        width: 100%;
    }
}

.level-card {
    border-radius: 8px;
    border: 1px solid #EDC483;
    color: #EDC483;
    text-align: center;
    display: inline-block;
    padding: 2px 6px;
}

.members-table /deep/ table {
    .ant-table-thead > tr > th {
        background: transparent;
        padding: 12px 12px;
    }
    
    .ant-table-column-title {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
    }
    
    .ant-table-tbody > tr > td {
        font-size: 14px;
        font-weight: 400;
        color: #606266;
        line-height: 14px;
        padding: 12px 12px;
    }
}

.search-tab {
    display: flex;
    justify-content: space-between;
}

.status-btn {
    cursor: auto;
    background-color: green;
}

.green-btn {
    background-color: green;
}

.mail-btn {
    background-color: orange;
    border-color: #dddddd;
}

.paper-item-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.paper-item {
    margin-bottom: 16px;
}

.paper-info {
    margin-right: 16px;
    text-align: center;
    border: 1px solid transparent;
    padding: 2px;
    
    &:active, &.active {
        border-color: skyblue;
    }
    
    img {
        width: 120px;
    }
}

.qrcode-container {
    display: flex;
    justify-content: center;
    align-items: center;
    
    img {
        width: 200px;
        margin-bottom: 10px;
    }
    
}

.third-app-avatar {
    margin-right: 5px;
    border-radius: 50%;
    overflow: hidden;
    
    img {
        width: 20px;
    }
}
</style>